//INFO: 프로젝트 게시판 수정, 보기
<script>
import { getLabPjt } from '@/api/platform/community' // 프로젝트 조회
import { updatePjtTable } from '@/api/admin/ntis/ntisUpdate' // 프로젝트 수정
import { getTotalPaperData, getTotalPatentData } from '@/api/admin/ntis/ntis' // 프로젝트에 맞는 논문

export default {
  name: 'adminBoardProjectUpdate',
  components: {},
  data() {
    const boardNo = Number(this.$route.params.id) // 파라미터 게시글 번호

    return {
      boardNo: boardNo,
      pjtPageNo: 0,
      labNo: 0,
      pjtNo: '', // 과제고유번호
      readProjectData: {}, // 선택된 프로젝트 데이터 보기
      paperDatas: [], // 프로젝트에 해당하는 논문
      patentDatas: [] // 프로젝트에 해당하는 지재권
    }
  },
  setup() {},
  created() {},
  mounted() {
    this.pjtPageNo = Number(this.$route.params.page) // 프로젝트 게시판 페이지 번호
    this.labNo = Number(this.$route.params.labNo) // 프로젝트 게시판 페이지 번호

    // 게시글 번호에 맞는 프로젝트 가져오기
    const loadPjtData = async () => {
      const data = await getLabPjt(this.boardNo)

      // 프로젝트 과제 기간 날짜 설정
      if (data.projectPeriodStart && data.projectPeriodEnd) {
        data.projectPeriodStart = data.projectPeriodStart.substr(0, 10)
        data.projectPeriodEnd = data.projectPeriodEnd.substr(0, 10)
      }

      // 과제 기준연도 날짜 설정
      if (data.projectYear) {
        data.projectYear = data.projectYear.substr(0, 4)
      }

      this.readProjectData = data

      // 프로젝트에 맞는 논문 가져오기
      const paperData = await getTotalPaperData(data.projectNumber, 'TI03=', '', '', '', '', '', '', '', '')
      if (paperData.LIST.length > 0) {
        this.paperDatas = paperData.LIST
      } else {
        this.paperDatas.push({ paperResultTitle: '프로젝트에 해당하는 논문이 없습니다.' })
      }

      // 프로젝트에 맞는 지재권 가져오기
      const patentData = await getTotalPatentData(data.projectNumber, 'TI03=', '', '', '', '', '', '', '', '')
      if (patentData.LIST.length > 0) {
        this.patentDatas = patentData.LIST
      } else {
        this.patentDatas.push({ patentResultTitle: '프로젝트에 해당하는 지재권가 없습니다.' })
      }
    }

    loadPjtData()
  },
  unmounted() {},
  methods: {
    // ********** 목록버튼 **********
    goProjectList() {
      this.$router.push({
        path: `/admin/board/project/${this.labNo}/${this.pjtPageNo}`
      })
    },
    // ********** 저장버튼 **********
    async saveProject() {
      const projectInputData = {
        projectStatus: this.readProjectData.projectStatus,
        projectPeriodStart: this.readProjectData.projectPeriodStart,
        projectPeriodEnd: this.readProjectData.projectPeriodEnd,
        projectAddTextKr: this.readProjectData.projectAddTextKr,
        projectAddTextEn: this.readProjectData.projectAddTextEn
      }

      // 프로젝트 수정 함수 호출
      updatePjtTable(this.boardNo, projectInputData)
      alert('프로젝트가 수정되었습니다.')
      this.goProjectList()
    }
  }
}
</script>

<template>
  <!-- 내용 입력 시작 -->
  <div class="adminTable">
    <div id="projectUpdateForm">
      <!-- 수정 불가 정보 시작 -->
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>
        <tbody>
          <tr>
            <th>연구실 번호</th>
            <td><input type="text" name="labNo" v-model="readProjectData.labNo" placeholder="연구실 번호" disabled size="50" /></td>
            <td></td>
            <td></td>
          </tr>
          <br />
          <span class="info_text">NTIS 정보</span>
          <tr>
            <th>과제명(한글)</th>
            <td>
              <input
                type="text"
                name="projectTitleKr"
                v-model="readProjectData.projectTitleKr"
                placeholder="과제명(한글)"
                disabled
                size="70"
              />
            </td>
            <th>예산사업명</th>
            <td>
              <input
                type="text"
                name="projectBudgetName"
                v-model="readProjectData.projectBudgetProject"
                placeholder="예산사업명"
                disabled
                size="70"
              />
            </td>
          </tr>
          <tr>
            <th>과제명(영어)</th>
            <td>
              <input
                type="text"
                name="projectTitleEn"
                v-model="readProjectData.projectTitleEn"
                placeholder="과제명(영어)"
                disabled
                size="70"
              />
            </td>
            <th>연구책임자명</th>
            <td>
              <input
                type="text"
                name="projectManagerName"
                v-model="readProjectData.projectManager"
                placeholder="연구책임자명"
                disabled
                size="70"
              />
            </td>
          </tr>
          <tr>
            <th>과제고유번호</th>
            <td>
              <input
                type="text"
                name="projectNumber"
                v-model="readProjectData.projectNumber"
                placeholder="과제고유번호"
                disabled
                size="70"
              />
            </td>
            <th>과제수행기관</th>
            <td>
              <input
                type="text"
                name="projectLeadAgency"
                v-model="readProjectData.projectLeadAgency"
                placeholder="과제수행기관"
                disabled
                size="70"
              />
            </td>
          </tr>
          <tr>
            <th>기준연도</th>
            <td><input type="text" name="projectYear" v-model="readProjectData.projectYear" placeholder="기준연도" disabled /></td>
            <th>부처명</th>
            <td>
              <input
                type="text"
                name="projectMinistryName"
                v-model="readProjectData.projectMinistry"
                placeholder="부처명"
                disabled
                size="70"
              />
            </td>
          </tr>
          <tr>
            <th>정부투자연구비</th>
            <td>
              <input
                type="text"
                name="projectGovernmentFunds"
                v-model="readProjectData.projectGovernmentFunds"
                placeholder="정부투자연구비"
                disabled
                size="70"
              />
            </td>
            <th>총 연구비</th>
            <td>
              <input
                type="text"
                name="projectTotalFunds"
                v-model="readProjectData.projectTotalFunds"
                placeholder="총 연구비"
                disabled
                size="70"
              />
            </td>
          </tr>
          <tr>
            <th>연구내용</th>
            <td>
              <textarea
                type="text"
                name="projectAbstract"
                v-model="readProjectData.projectAbstract"
                placeholder="요약정보"
                disabled
              ></textarea>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>논문</th>
            <td class="paperList">
              <ul>
                <li v-for="(paper, idx) in paperDatas" :key="idx">
                  <span>{{ paper.paperResultID }}</span>
                  <p>{{ paper.paperResultTitle }}</p>
                </li>
              </ul>
            </td>
            <th>지재권</th>
            <td class="patentList">
              <ul>
                <li v-for="(patent, idx) in patentDatas" :key="idx">
                  <span>{{ patent.patentResultID }}</span>
                  <p>{{ patent.patentResultTitle }}</p>
                </li>
              </ul>
            </td>
          </tr>
          <br />
        </tbody>
      </table>
      <!-- 수정 불가 정보 끝 -->

      <!-- 수정 가능 정보 시작 -->
      <span class="info_text">추가입력정보</span>
      <table>
        <colgroup>
          <col width="10%" />
          <col width="40%" />
          <col width="10%" />
          <col width="40%" />
        </colgroup>
        <tbody>
          <tr>
            <th>프로젝트 상태</th>
            <td>
              <select name="projectStatus" id="projectStatus" v-model="readProjectData.projectStatus">
                <option value="선택" selected>선택</option>
                <option value="진행중">진행중</option>
                <option value="완료">완료</option>
              </select>
            </td>
            <td></td>
            <td></td>
          </tr>
          <br />
          <tr>
            <th>과제기간</th>
            <td>
              <input type="date" name="projectPeriodStart" v-model="readProjectData.projectPeriodStart" placeholder="시작일" />
              <span> ~ </span>
              <input type="date" name="projectPeriodEnd" v-model="readProjectData.projectPeriodEnd" placeholder="종료일" />
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <th>추가내용</th>
            <td>
              <textarea
                type="text"
                name="projectAddTextKr"
                v-model="readProjectData.projectAddTextKr"
                placeholder="요약정보(한글)"
              ></textarea>
            </td>
            <td></td>
            <td>
              <textarea
                type="text"
                name="projectAddTextEn"
                v-model="readProjectData.projectAddTextEn"
                placeholder="요약정보(영어)"
              ></textarea>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- 수정 가능 정보 끝 -->

      <div class="btnArea">
        <button class="e-btn wf__btn btn-primary" @click="goProjectList()">목록</button>
        <button id="saveProjectBtn" class="e-btn wf__btn btn-success ml-2" @click="saveProject">저장</button>
      </div>
    </div>
  </div>
  <!-- 내용 입력 끝 -->
</template>

<style scoped>
.info_text {
  color: #2a7097;
  margin-left: 10px;
  font-size: x-large;
}
#projectUpdateForm textarea {
  width: 540px;
  height: 100px;
}
/* 프로젝트 상태 select */
#projectStatus {
  width: 150px;
  height: 30px;
  border: 1px solid #ededed;
  -webkit-appearance: none; /* 화살표 제거 for chrome */
  -moz-appearance: none; /* 화살'표 제거 for firefox */
  appearance: none;
  background: url('../../../../assets/images/icons/downArrowIcon.png') calc(100% - 5px) center no-repeat;
  background-size: 20px;
  padding: 5px 30px 5px 10px;
  color: #acacac;
  font-weight: 500;
}
#projectStatus::-ms-expand {
  display: none; /* html selectbox 기본 스타일 제거 */
}
#projectStatus option {
  background: #fff;
  color: #18a1e9;
  padding: 3px 0;
}
.btnArea {
  margin-top: 30px;
}
.paperList ul,
.patentList ul {
  height: 250px;
  width: 600px;
  margin-left: -15px;
  overflow-y: scroll;
}
.paperList ul li,
.patentList ul li {
  margin-bottom: 5px;
  border-bottom: 1px solid #efefef;
}
.paperList ul li p,
.patentList ul li p {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-overflow: ellipsis; /* 말줄임 적용 */
}
.paperList ul li span,
.patentList ul li span {
  margin-bottom: -10px;
}
</style>
