import { useAxios } from '../../axiosUtil'

const token = localStorage.getItem('token')
const tokenType = localStorage.getItem('tokenType')
const headerOpt = {
  method: 'PUT',
  headers: {
    Authorization: `${tokenType} ${token}`
  }
}

/**
 * 프로젝트 수정 함수
 * @param {} boardNo 파라미터로 받는 게시글 번호
 * @param {*} inputData 사용자 입력 데이터
 */
export function updatePjtTable(boardNo, inputData) {
  let pjtUrl = '/v1/board/lab-project' // 프로젝트 url

  // 사용자 입력 데이터 savePjtData에 담기
  const savePjtData = {
    projectStatus: inputData.projectStatus,
    projectPeriodStart: inputData.projectPeriodStart,
    projectPeriodEnd: inputData.projectPeriodEnd,
    projectAddTextKr: inputData.projectAddTextKr,
    projectAddTextEn: inputData.projectAddTextEn
  }
  const pjtDataOpt = { data: savePjtData, ...headerOpt }

  pjtUrl = `${pjtUrl}/${boardNo}`

  useAxios(pjtUrl, pjtDataOpt)

  // boardNo 값 url에 중복으로 들어가는 것 방지
  boardNo = ''
}

/**
 * 논문 수정 함수
 * @param {} boardNo 파라미터로 받는 게시글 번호
 * @param {*} inputData 사용자 입력 데이터
 */
export function updatePaperTable(boardNo, inputData) {
  let paperUrl = '/v1/board/lab-paper' // 논문 url

  // 사용자 입력 데이터 savePjtData에 담기
  const savePaperData = { paperIssueDate: inputData }
  const paperDataOpt = { data: savePaperData, ...headerOpt }

  paperUrl = `${paperUrl}/${boardNo}`

  useAxios(paperUrl, paperDataOpt)

  // boardNo 값 url에 중복으로 들어가는 것 방지
  boardNo = ''
}
